<template>
  <div>
    <BaseLoadingBarSheet v-if="loadingBarContent" class="loading-content" />

    <div id="form-view" :style="{ width: formWidth }">
      <!-- form meta -->

      <div class="header">
        <div class="col">
          <div class="title-1">{{ formName }}</div>
          <div class="description">{{ formDescription }}</div>
        </div>

        <BaseActionButton
          is-flat
          :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
          @click="toggleWidth"
        />
        <ThemeSwitcher class="q-ml-sm" :no-border="false" />
      </div>

      <!-- ... -->

      <!-- form -->
      <RenderForm
        v-if="form"
        :form-id="formId"
        :panels="panels"
        :secondary-panels="secondaryPanels"
        :form-settings="formSettings"
        :form-model="formModel"
        :enable-controls="_enableControls"
        :height="'calc(100vh - 200px)'"
        :external-link="true"
        :portal-id="portal.id"
        :external-loading.sync="externalLoading"
        @save="saveForm"
        @formSaveType="formSaveType"
      />

      <!-- ... -->

      <!-- QR Print -->

      <QRView
        v-model="isQRView"
        :form-id="formId"
        :form-entry-id="formEntryId"
        @input="closeQRModal"
      />

      <!-- ... -->

      <!-- footer -->

      <div class="footer">
        <div class="label">Powered by</div>
        <img src="@/assets/logo/logo-light.png" alt="logo" />
      </div>

      <!-- ... -->

      <!-- modal -->

      <Modal
        v-model="submissionModal"
        width="30vw"
        height="50vh"
        has-footer
        @input="closeSubmission()"
      >
        <!-- title -->

        <template #title>Thank you!</template>

        <!-- ... -->

        <template #default>
          <BaseIcon
            name="eva-checkmark-circle-2-outline"
            color="positive"
            size="80px"
            class="imgCenter"
          />
          <div class="text-center">
            Thank you for submitting the form. We appreciate your participation
            and the information you have provided.
          </div>
        </template>

        <!-- footer -->

        <template #footer>
          <BaseButton label="Close" @click="closeSubmission" />
        </template>
      </Modal>

      <!-- ... -->
    </div>
  </div>
</template>

<script>
import { auth, form, portal } from "@/api/factory.js";
import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import QRView from "@/components/common/QRView.vue";
import Modal from "@/components/common/popup/Modal.vue";

export default {
  name: "FormView",

  components: { ThemeSwitcher, RenderForm, QRView, Modal },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    formId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loadingBarContent: true,
      form: null,
      formWidth: "720px",
      formModel: {},
      isQRView: false,
      formEntryId: 0,
      submissionModal: false,
      sendInviteBtn: false,
      portal: {},
      savingType: "",
      formItemId: 0,
      externalLoading: false,
    };
  },

  computed: {
    isMaximized() {
      return this.formWidth === "100%";
    },

    formName() {
      if (!this.form) {
        return "";
      }

      return this.formSettings.general.name;
    },

    formDescription() {
      if (!this.form) {
        return "";
      }

      return this.formSettings.general.description;
    },

    panels() {
      if (!this.form) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.form) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.form) {
        return {};
      }

      return this.form.settings;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (
                          controls.indexOf(control) < 0 &&
                          listControls.indexOf(control) < 0
                        ) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },
  },

  watch: {
    tenantId: {
      immediate: true,
      handler: "getSession",
    },

    externalLoading: {
      immediate: true,
      handler() {
        if (this.externalLoading) {
          this.loadingBarContent = true;
        } else {
          this.loadingBarContent = false;
        }
      },
    },
  },

  methods: {
    toggleWidth() {
      this.formWidth = this.formWidth === "100%" ? "720px" : "100%";
    },

    async getSession() {
      this.loadingBarContent = true;
      const { error } = await auth.externalLogin(this.tenantId, 1);
      if (error) {
        this.loadingBarContent = false;
        this.$alert.info("User Account Not Found");
        return;
      }
      this.getForm();
      this.getPortals();
    },

    async getForm() {
      const { error, payload } = await form.getForm(this.formId);
      this.loadingBarContent = false;

      if (error) {
        this.loadingBarContent = false;
        this.$alert.error(error);
        return;
      }

      this.form = JSON.parse(payload.formJson);
    },

    async getPortals() {
      this.portal = {};
      this.sendInviteBtn = false;
      const { error, payload } = await portal.getPortalList({
        mode: this.mode,
        sortBy: this.sortBy,
        groupBy: this.groupBy,
        filterBy: [
          {
            filters: [
              {
                criteria: "settingsJson",
                condition: "CONTAINS",
                value:
                  '"loginType":"MASTER_LOGIN","formId":' + this.formId + ",",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      if (meta.totalItems) {
        if (data.length) {
          if (data[0].value.length) {
            this.portal = data[0].value[0];
            if (this.portal.settingsJson) {
              let settings = JSON.parse(this.portal.settingsJson);
              if (
                settings.authentication.loginType === "MASTER_LOGIN" &&
                settings.authentication.notification
              ) {
                this.sendInviteBtn = true;
              }
            }
          }
        }
      }
    },

    async saveForm(fieldsModel) {
      this.loadingBarContent = true;
      let input = {
        fields: fieldsModel,
      };
      let entryId = 0;
      if (this.savingType === "ADD") {
        entryId = 0;
      } else if (this.savingType === "UPDATE") {
        entryId = this.formItemId;
      }
      const { data, error } = await form.saveFormEntry(
        this.formId,
        entryId,
        input
      );

      this.loadingBarContent = false;

      if (error) {
        if (error.data) {
          let errorData = JSON.parse(error.data);
          console.log(errorData);
          let fields = JSON.parse(errorData.output);
          let fieldNames = [];
          fields.forEach((row) => {
            fieldNames.push(row.name);
          });
          this.$alert.warning("Already Exists!", fieldNames);
        }
        this.$alert.error(error);
        return;
      }

      if (this.sendInviteBtn) {
        this.sendInvite(data.output);
      }

      if (this.form.settings.general.qrFields) {
        if (this.form.settings.general.qrFields.length) {
          let entryId = data.output;
          this.formEntryId = parseInt(entryId);
          this.isQRView = true;
        }
      } else {
        //window.location.reload();
        this.submissionModal = true;
      }
    },

    closeQRModal() {
      this.submissionModal = true;
      //window.location.reload();
    },

    closeSubmission() {
      this.submissionModal = false;
      window.location.reload();
    },

    async sendInvite(entryId) {
      let origin = location.origin;
      let linkText = "";
      if (
        origin === "https://app.ezofis.com" ||
        origin === "https://appuat.ezofis.com" ||
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "https://trial.ezofis.com"
      ) {
        linkText = `${origin}/portals/${this.$store.state.session.tenantId}/${this.portal.id}`;
      } else {
        linkText = `${origin}/app/portals/${this.$store.state.session.tenantId}/${this.portal.id}`;
      }

      let settingsJson = JSON.parse(this.portal.settingsJson);

      let input = {
        formId: this.formId,
        entryIds: [entryId],
        fieldName: settingsJson.authentication.usernameField,
        inviteUrl: linkText,
      };
      const { error } = await form.sendInvite(this.portal.id, input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success(`Invite Sent`);
    },

    formSaveType(type, itemId) {
      console.log(type, itemId);
      this.savingType = type;
      if (type === "ADD") {
        this.formItemId = 0;
      } else {
        this.formItemId = itemId;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#form-view {
  margin: auto;
  padding: 16px;

  .header {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }
}

#modal .imgCenter {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
</style>
